import apiService from "./ApiServices";
import { APIurls, basePath } from "./constant";

export const ApiRequests = {
  register: async (data) =>
    await apiService.post(`${basePath}/${APIurls.register}`, data, {
      withCredentials: true,
    }),
  login: async (data) =>
    await apiService.post(`${basePath}/${APIurls.login}`, data, {
      withCredentials: true,
    }),
  logout: async (data) =>
    await apiService.post(`${basePath}/${APIurls.logout}`, data, {
      withCredentials: true,
    }),
  forget: async (data) =>
    await apiService.post(`${basePath}/${APIurls.forget}`, data, {
      withCredentials: true,
    }),
  reset: async (data) =>
    await apiService.post(
      `${basePath}/${APIurls.reset}`,
      { password: data.password },
      {
        params: { token: data.token }, // Use 'params' for query parameters
        withCredentials: true,
      }
    ),

  // reset: async (data, token) => await apiService.post(`${basePath}/${APIurls.reset }` + "?token=" + token, data, {withCredentials: true,}),
};
