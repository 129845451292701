import React from "react";
import { Form, Button } from "react-bootstrap";
// import Link from "next/link";
// import Header from "../src/components/Header";
import Footer from "../components/Footer";
import { userForgetAsyncThunk } from "../store/authSlice";
import { toast } from "react-toastify";
import { useState } from "react";
// import { useRouter } from "next/router";
import {Link, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Image from "../assets/images/tokcomment-header.png"
const Forget = () => {
  const router = useNavigate()
  const [email, setEmail] = useState('');
  const dispatch = useDispatch()
  const forget = useSelector((state) => state?.auth);
  console.log('forget', forget)
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(userForgetAsyncThunk({ email,router }));
  };
  return (
    <>
      {/* <Header /> */}
      <div className="login-container">
        <div className="login-form mt-5 main-card">
        <Link href='/login'>
      <img
        src={Image}
        className="my-auto main-logo ml-5 w-52 mb-5"
        alt="TokTools logo"
      />
      </Link>
          {/* <div className="alert-icon">
          <i className="fa-regular fa-circle-exclamation"></i>
          </div> */}
          {/* <div className="hello forgetscreen">
            <h2>Forget password ?</h2>
          </div> */}

          <div className="sign-in">
            <div className="form-group">
              <div className="input-with-icon">
                <i className="fa-light icon icon-tabler icon-tabler-mail fa-envelope"></i>
                <input
                  name="email"
                  type="email"
                  className={`form-control form-control-lg 
                            
                          }`}
                  placeholder="Email"
                  value={email} onChange={(e) => setEmail(e.target.value)}
                />

                <Button
                  variant=""
                  className="pink-login forgetbtn"
               onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
          {/* 
<Form method="post" onSubmit={handleSubmit}>
<div className="form-group">
<div className="input-with-icon">
<i className="fa-light icon icon-tabler icon-tabler-mail fa-lock-keyhole"></i>
<input
name="password"
type="password"
className={form-control form-control-lg}
placeholder="Enter Passwprd"
// onChange={handleChange}
// onBlur={handleBlur}
/>
</div>
</div>
<div className="form-group">
<div className="input-with-icon">
<i className="fa-light icon icon-tabler icon-tabler-mail fa-lock-keyhole"></i>
<input
name="password"
type="password"
className={form-control form-control-lg}
placeholder="confirm Password"
// value={values.password}
// onChange={handleChange}
// onBlur={handleBlur}
/>
</div>
<Button variant="" className="pink-login" type="submit">
Reset
</Button>
</Form> */}

          {/* <div className="lastlines">
            <h3>
              Forgot password?
              <span>
                <Link href="/reset">Reset</Link>
              </span>
            </h3>
          </div>
          <div className="lastlines">
            <h3>
              Don't you have an account?
              <span>
                <Link href="/register">Sign Up</Link>
              </span>
            </h3>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Forget;
