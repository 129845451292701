import tokcommentHeader from '../assets/images/tokcomment-header.png';
import React, { useState } from 'react';



//add footer componenet 
const Footer = () => {




    return (
        <footer className="bg-white text-gray-700 pb-2 pt-6">
            <div className="container mx-auto px-6 py-10 md:px-12">
                <div className="flex flex-wrap -mx-6 gap-y-12" >
                    <div className="w-full md:w-1/2 lg:w-1/4 px-6 mb-6 md:mb-0">
                        <img src={tokcommentHeader} className="logo mb-4" alt="TokTools logo" />
                        <a href="mailto:support@toktools.com" className="block mb-4" style={{textDecoration:"none"}}>
                            <button className="email-btn text-gray-700 ">
                                Email Us
                            </button>
                        </a>
                      
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/4 px-6 mb-6 lg:mb-0">
                        <h1 className="text-xl font-bold mb-4">
                            Other Tools
                        </h1>
                        <ul className="text-sm pl-0">
                            <li className="mb-2">
                                <a href="https://www.tokbackup.com/" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    TokBackup
                                </a>
                            </li>
                            <li className="mb-2">
                                <a href="https://www.tokaudit.io/" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    TokAudit
                                </a>
                            </li>
                            <li className="mb-2">
                                <a href="https://chromewebstore.google.com/detail/tiktok-ads-video-download/defjbhlehbkebaifofnaalipneaeaick" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    TokView
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/4 px-6">
                        <h1 className="text-xl font-bold mb-4">
                            Resources
                        </h1>
                        <ul className="text-sm pl-0">
                            <li className="mb-2">
                                <a href="https://ttcomments.tokbackup.com/" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    TokAnalyze
                                </a>
                            </li>
                            <li className="mb-2">
                                <a href="https://script.tokaudit.io/" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    TokScript
                                </a>
                            </li>
                            <li className="mb-2">
                                <a href="https://talkthetok.com/" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    TikTok Podcast
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/4 px-6">
                        <h1 className="text-xl font-bold mb-4">
                            Helpful Links
                        </h1>
                        <ul className="text-sm pl-0" >
                        <li className="mb-2">
                                <a href="https://tokdownload.com" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                TokDownload
                                </a>
                            </li>
                            <li className="mb-2">
                                <a href="https://chat.openai.com/g/g-9BGRz0SfJ-viral-intro-hooks" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    HooksGPT
                                </a>
                            </li>
                            <li className="mb-2">
                                <a href="https://chat.openai.com/g/g-0y72K7Njo-viral-ads-script" target="_blank" rel="noopener noreferrer" className='text-gray-700' style={{textDecoration:"none"}}>
                                    ViralAdsGPT
                                </a>
                            </li>
                         

                        </ul>
                    </div>
                </div>

            

            </div>
            <p 
            //className="text-sm"
            className="text-center text-sm text-gray-500 mt-10"
            >
                            TOKcomment.com © {new Date().getFullYear()} - All rights reserved
                        </p>
        </footer>

    )
}

export default Footer