export const basePath =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api/v1"
    : "https://tokcommentv3api.toktools.online/api/v1";

export const APIurls = {
  // auth
  login: "auth/login",
  register: "auth/register",
  logout: "auth/logout",
  reset: "auth/reset-password",
  forget: "auth/forgot-password",
};
