// ** Reducers Imports
import auth from "./pagesSlices/authSlice";
import user from "./pagesSlices/userSlice";
import selected from "./pagesSlices/selectedOptionReducerSlice";

import error from "./errors/handleErrorsAndPayloads";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  selected,
  user,
  error,
  auth,
});

export default rootReducer;
