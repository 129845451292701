import React from "react";
import defaultProfileImage from "../assets/images/default-profile-image.png"; // Adjust the import path as necessary
import verifiedIcon from "../assets/images/verified.svg";

const CommentBubble = ({ username, isVerified, imageSrc, text, comment, imageSrcRandom }) => {
  console.log("🚀 ~ CommentBubble ~ imageSrc:", imageSrc)
  console.log("🚀 ~ CommentBubble ~ imageSrcRandom:", imageSrcRandom)
  // console.log("🚀 ~ CommentBubble ~ isVerified:", isVerified);
  const formattedComment = text ? (
    <>
      <span className="mb-0">{text ? text : ""}</span>
      {/* <img
        className="w-[25px] h-[25px] object-cover rounded-full"
        src={comment?.imgUrl}
        alt="Profile"
        style={{
          width: "18px",
          height: "18px",
          objectFit: "cover",
          borderRadius: "50%",
        }}
      /> */}
    </>
  ) : (
    "Write any comment and see what happens 😁"
  );
  return (
    <div className="relative  bg-white w-fit h-fit pl-[8px] pt-[6px] pr-[4px]  pb-[8px] rounded-bl-none  mx-auto rounded-[5px] flex flex-col after:content-[''] after:rounded-bl-[5px] after:absolute after:bottom-[-9px] after:left-0 after:w-0 after:h-0 after:border-[11px] after:border-t-white after:border-r-transparent after:border-b-0 after:border-l-0">
      <p className="text-[#8b8b8b] ml-[23px] text-[7.5px] leading-[0.65rem] -mb-0 font-semibold whitespace-nowrap">
        Reply to {username || "username"}'s{" "}
        {isVerified && (
          <span
            style={{ backgroundImage: `url(${verifiedIcon})` }}
            className="bg-no-repeat bg-contain w-[6px] h-[6px] inline-block"
          ></span>
        )}{" "}
        comment
      </p>

      <div className="mt-1 flex gap-[5px] leading-4">
        <img
          className="w-[25px] h-[25px] object-cover rounded-full "
          src={imageSrc ? imageSrc : imageSrcRandom || defaultProfileImage}
          alt="Profile"
          style={{
            width: "18px",
            height: "18px",
            objectFit: "cover",
            borderRadius: "50%",
          }}
        />

        <p
          className="break-words font-bold text-black text-[12px] mt-[1px] mb-0 tracking-tight leading-[1.15] w-fit  "
          style={{ fontWeight: "700", maxWidth: "120px" }}
        >
          {formattedComment}
        </p>
      </div>
    </div>
  );
};

export default CommentBubble;
