import React, { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import BulkTableRow from "../components/BulkTableRow";
import { basePath } from "../service/constant";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const BulkTabComponent = ({
  setIsDropdownVisible,
  commentsInfo,
  setCommentsInfo,
  selectedCommentIndex,
  handleInputChange,
  // removeComment,
  useClickOutside,
  handleImageChange,
  AiOutlinePlus,
  AiOutlineFileExcel,
  selectedTab,
}) => {
  const [bulkDownloadLoading, setBulkDownloadLoading] = useState(false);

  const [lastImages, setLastImages] = useState([]);
  const [uploadResponse, setUploadResponse] = useState(null);
  console.log("🚀 ~ uploadResponse:", uploadResponse);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const celebrityUsernames = {
    1: "@ArianaGrande",
    2: "@BarackObama",
    3: "@Beyonce",
    4: "@ElonMusk",
    5: "@JasonDerulo",
    6: "@JoeRogan",
    7: "@JustinBieber",
    8: "@KatyPerry",
    9: "@imKevinHart",
    10: "@KhloeKardashian",
    11: "@KimKardashian",
    12: "@KylieJenner",
    13: "@mrbeast",
    14: "@NickiMinaj",
    15: "@JoeBiden",
    16: "@SelenaGomez",
    17: "@TaylorSwift",
    18: "@TheRock",
    19: "@WillSmith",
    20: "@RealDonaldTrump",
    21: "@Rihanna",
  };
  function getRandomImage(category) {
    let maxImages = 5;
    let extension = ".png";
    if (category === "celebrity") {
      maxImages = 21;
    } else if (category === "male") {
      maxImages = 41;
    } else if (category === "female") {
      maxImages = 25;
    }
    let randomNumber;

    do {
      randomNumber = Math.floor(Math.random() * maxImages) + 1;
    } while (lastImages.includes(`${category}/${randomNumber + extension}`));

    const newLastImages = [
      `${category}/${randomNumber + extension}`,
      ...lastImages.slice(0, 2),
    ];
    setLastImages(newLastImages);

    return {
      newImageSrc: require(`../assets/random-profile-images/${category}/${
        randomNumber + extension
      }`),
      newUsername: category === "celebrity" && celebrityUsernames[randomNumber],
    };
  }

  const selectMenuItem = (category, index) => {
    // If no index is given then use the selectedCommentIndex
    let newIndex = index || selectedCommentIndex;
    setCommentsInfo((prev) =>
      prev?.map((comment, i) => {
        let verified = comment?.isVerified;
        if (category === "celebrity") {
          verified = true;
        }
        if (i === newIndex) {
          let { newImageSrc, newUsername } = getRandomImage(category);
          return {
            ...comment,
            imageSrc: newImageSrc,
            username: newUsername ? newUsername : comment.username,
            isVerified: verified,
          };
        }
        return comment;
      })
    );

    // Close the dropdown
    setIsDropdownVisible(false);
  };
  const handleFileUpload = async (event) => {
    console.log("UPLOADING FILE: ");
    const file = event.target.files[0];
    console.log("FILE: ", file);

    const reader = new FileReader();
    setCommentsInfo([]);

    //Load file & read its content
    reader.onload = (e) => {
      const excelFile = e.target.result;

      const workBook = XLSX.read(excelFile, { type: "binary" });
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];
      const data = XLSX.utils.sheet_to_json(workSheet);
      console.log("DATA: ", data);

      // Updating states
      const updatedCommentsInfo = [...data];
      setCommentsInfoForBulk(updatedCommentsInfo);
    };

    reader.readAsArrayBuffer(file);

    console.log("BULK COMMENTS: ", commentsInfo);
    // try {
    //   const accessToken = JSON.parse(localStorage.getItem("app-access-token"));
    //   const headers = {
    //     Authorization: `Bearer ${accessToken}`,
    //     "Content-Type": "multipart/form-data",
    //   };

    //   const response = await axios.post(
    //     `${basePath}/comment-sheet/excel-file`,

    //     // const response = await axios.post(
    //     //   "http://localhost:8000/api/v1/comment-sheet/excel-file",
    //     formData,
    //     {
    //       headers,
    //       withCredentials: true,
    //     }
    //   );

    //   setUploadResponse(response.data);
    // } catch (error) {
    //   console.error("Error uploading file:", error);
    // }
  };

  // Function to download the zip file
  const onClickZipDownload = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setBulkDownloadLoading(true);
      const accessToken = JSON.parse(localStorage.getItem("app-access-token"));
      console.log("🚀 ~ onClickZipDownload ~ accessToken:", accessToken);
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        // 'Content-Type': 'application/json',
        "Content-Type": "multipart/form-data",
      };

      let requestData = [];
      if (uploadResponse?.excelData) {
        requestData = [...uploadResponse.excelData];
      }
      if (Array.isArray(commentsInfoForBulk)) {
        requestData = [...requestData, ...commentsInfoForBulk];
      }

      const responseData = await axios.post(
        `${basePath}/bulk/guest-comment-bulk/`,
        requestData
      );
      console.log("🚀 ~ onClickZipDownload ~ responseData:", responseData);

      if (responseData?.status == 201) {
        toast.success("Successfully Created!");
        setCommentsInfoForBulk([initialCommentInfo]);
        setUploadResponse(null);
        setCommentsInfo([]);
      }
      setBulkDownloadLoading(false);

      const response = await axios.get(
        `${basePath}/comment-sheet/get-comment-img`,
        {
          headers,
          withCredentials: true,
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/zip" });
      setLoading(false);
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "images.zip";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setBulkDownloadLoading(false);
      if (response?.request?.status === 200) {
        setShow(false);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      if (error?.response?.data?.code === 500) {
        toast.error(error?.response?.data?.message);
      }
      setBulkDownloadLoading(false);
      setLoading(false);
      setCommentsInfoForBulk([initialCommentInfo]);
      setUploadResponse(null);
      setCommentsInfo([]);
    }
  };

  const initialCommentInfo = {
    userName: "",
    comment: "",
    verified: false,
    avatar: "",
    commentType: "tiktok_comment",
  };
  const [commentsInfoForBulk, setCommentsInfoForBulk] = useState([
    initialCommentInfo,
  ]);
  console.log("commentsInfoForBulk>>>>>>>>>>>>>>>>>", commentsInfoForBulk);

  const addComment = () => {
    setCommentsInfoForBulk([...commentsInfoForBulk, initialCommentInfo]);
  };

  const handleBulkInputChange = (index, event) => {
    if (event && event.target) {
      const { name, value } = event.target;
      console.log("target.........", name, value);

      setCommentsInfoForBulk((prevComments) => {
        return prevComments.map((comment, i) => {
          if (i === index) {
            return {
              ...comment,
              [name]: value,
            };
          }
          return comment;
        });
      });
    }
  };

  const removeComment = (index) => {
    const updatedCommentsInfo = commentsInfoForBulk.filter((_, i) => {
      console.log("🚀 ~ removeComment ~ i:", i);
      return i !== index;
    });
    console.log({ updatedCommentsInfo });
    // setSelectedCommentIndex(index - 1 >= 0 ? index - 1 : 0);
    setCommentsInfoForBulk(updatedCommentsInfo);
  };

  return (
    <div className="flex flex-col items-center mt-4 gap-y-6">
      <table className="min-w-full text-xs divide-y divide-gray-200">
        <thead>
          <tr>
            <th
              className="px-2 py-2 font-medium tracking-wider text-center text-gray-400 uppercase"
              style={{ width: "70px" }}>
              User
            </th>
            <th
              className="px-2 py-2 font-medium tracking-wider text-center text-gray-400 uppercase"
              style={{ width: "100px" }}>
              Comment
            </th>
            <th className="px-2 py-2 font-medium tracking-wider text-center text-gray-400 uppercase">
              Ver.
            </th>
            <th className="px-2 py-2 font-medium tracking-wider text-center text-gray-400 uppercase">
              Pic
            </th>
            <th className="px-2 py-2 font-medium tracking-wider text-center text-gray-400 uppercase"></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {[...commentsInfoForBulk, ...(uploadResponse?.excelData || [])].map(
            (comment, index) => (
              <tr
                className="transition cursor-pointer hover:bg-gray-50 focus:outline-none active:bg-gray-100 "
                // onClick={() => setSelectedCommentIndex(index)}
              >
                <BulkTableRow
                  key={index}
                  comment={comment}
                  index={index}
                  handleBulkInputChange={handleBulkInputChange}
                  removeComment={removeComment}
                  selectMenuItem={selectMenuItem}
                  useClickOutside={useClickOutside}
                  // handleImageChange={handleImageChange}
                />
              </tr>
            )
          )}
        </tbody>
      </table>

      <div className="flex items-start justify-center mt-8 space-x-2 ">
        {/* ADD Button */}
        <button
          className="rounded-md bg-gray-700 px-3 py-1.5 text-sm text-white shadow-sm hover:bg-gray-800 inline-flex items-center"
          onClick={addComment}>
          <AiOutlinePlus className="mr-2" /> New Row
        </button>

        {/* Upload Excel Button and Download Link */}
        <div className="flex flex-col items-center">
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={(event) => handleFileUpload(event)}
            accept=".xlsx, .xls , .csv, .ods"
          />
          <label
            htmlFor="fileInput"
            className="rounded-md bg-gray-100 px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-200 inline-flex items-center cursor-pointer">
            <AiOutlineFileExcel className="mr-2" />
            {/* upload file */}
            Add in bulk
          </label>
          <Link
            // href={`${process.env.PUBLIC_URL}/tokcomment_demo.xls`}
            // EDIT: https://docs.google.com/spreadsheets/d/1x4sHp4HtlP84_yF70D48Kd_Fq5LE6knx/edit?usp=sharing&ouid=114058293350886644909&rtpof=true&sd=true&export?format=xlsx
            to="https://docs.google.com/spreadsheets/d/1x4sHp4HtlP84_yF70D48Kd_Fq5LE6knx/export?format=xlsx"
            download="TokComment Demo.xls"
            className="w-24 mt-3 text-xs text-gray-500 cursor-default opacity-90"
            style={{
              textDecoration: "none",
            }}>
            <span className="mt-1 font-semibold cursor-pointer hover:text-gray-700">
              Click here
            </span>
            <span> to download demo excel</span>
          </Link>
        </div>
      </div>
      <div
        style={{
          padding: "10px",
          overflow: "hidden",
          width: "100%",
        }}>
        {" "}
        {/* Adjust padding as needed */}
        <button
          id="download"
          type="submit"
          disabled={bulkDownloadLoading}
          key={selectedTab}
          className={`download-btn mt-12  py-3 text-sm rounded-full font-medium transition-all w-full duration-300 ease-in-out ${
            bulkDownloadLoading
              ? "opacity-50"
              : "bg-gradient-to-r from-green-500 to-blue-500 text-white hover:scale-105"
          }`}
          onClick={(e) => onClickZipDownload(e)}>
          {bulkDownloadLoading ? "Loading..." : "Download ALL Comments"}
        </button>
      </div>
    </div>
  );
};

export default BulkTabComponent;
