import React from "react";
import { Routes, Route } from "react-router-dom"; // Import Route

import "./App.scss";
import Home from "./pages/Home";
import Register from "./pages/register";
import Login from "./pages/login";
import Forget from "./pages/forget";
import Reset from "./pages/reset";
import ProtectedRoute from "./components/ProtectedRoute.js";
function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />

      {/* <Route
        path="/"
        element={
          <ProtectedRoute allowedRoles={["admin"]}>
            <Home />
          </ProtectedRoute>
        }
      /> */}

      <Route exact path="/register" element={<Register />} />
      <Route exact path="/login" element={<Login />} />
      <Route path="/forget" element={<Forget />} />
      <Route path="/reset" element={<Reset />} />
    </Routes>
  );
}

export default App;
