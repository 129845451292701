import React, { useState } from "react";
import { FaArrowRight, FaCheck } from "react-icons/fa"; // Import icons

import tokcommentHeader from "../assets/images/tokcomment-header.png";
import tokcommentLogo from "../assets/images/tokcomment-logo.png";

import EmailIcon from "./EmailIcon";
import { Link } from "react-router-dom";
import { userLogoutAsyncThunk } from "../store/authSlice";
import { useDispatch } from "react-redux";
const Header = () => {
  const d = useDispatch();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [isSuccess, setIsSuccess] = useState(false); // New success state

  const submitEmail = async () => {
    setIsLoading(true); // Start loading

    const data = JSON.stringify({
      external_embed_id: "f327f2ce-ccf2-4221-a72e-398b77023f46",
      publication_id: "bcbec892-ba03-4fec-824a-3661350e8f97",
      email: email,
      user_agent:
        "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36",
    });

    try {
      const response = await fetch("https://embeds.beehiiv.com/api/submit", {
        method: "POST",
        headers: {
          authority: "embeds.beehiiv.com",
          accept: "application/json",
          "accept-language": "en-US,en;q=0.9,be;q=0.8,ar;q=0.7",
          "cache-control": "no-cache",
          "content-type": "application/json",
          cookie:
            "__cf_bm=.6gV0jbjUwv9xW66jpjJndWxq8WPkBjQwn96prZbWRA-1706318635-1-AeyAmYrKWN1Y4d8E2dj/vJba4oaqZGZMCZ4UPd2cq/zWzm+kNlrFTC69DIzOJoCsKkCNLbyzXLgkWdP2UMcc8JI=; cf_clearance=udIZNhGdqjoslhkC4vzUlNzW9NZp2ZaCKN.U5HHp2Aw-1706318636-1-AUOJ1YBoviUTsz7bTSdeXUYovyemw3ygA2lsH0Vh3dHs0uge6Qxo1VGbN2u2tUyeram0dPgoBid7Vza+hHC7pPQ=; __cf_bm=zEpZYY8jr_jPmy3T9yOiqGbLb2xMKX9PnRe0iEFqbYw-1706319187-1-AS02V8/qQtf25aAlgUMGkUhYxO+qenEyPFQe0FUyybp1Rv6NN8j14FrRswOeX3Kwfl+jp29nKQFaPD1z5SnsuA0=",
          dnt: "1",
          origin: "https://embeds.beehiiv.com",
          pragma: "no-cache",
          referer:
            "https://embeds.beehiiv.com/f327f2ce-ccf2-4221-a72e-398b77023f46?slim=true",
          "sec-ch-ua":
            '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
          "sec-ch-ua-mobile": "?1",
          "sec-ch-ua-platform": '"Android"',
          "sec-fetch-dest": "empty",
          "sec-fetch-mode": "cors",
          "sec-fetch-site": "same-origin",
          "user-agent":
            "Mozilla/5.0 (Linux; Android 13; Pixel 7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Mobile Safari/537.36",
        },
        body: data,
      });

      const responseData = await response.json();
      console.log(JSON.stringify(responseData));

      //wait for 3 seconds
      // await new Promise(resolve => setTimeout(resolve, 3000));
      setIsSuccess(true); // Set success state
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Stop loading regardless of success or error
    }
  };

  const handleLogout = () => {
    console.log("Hanlde Logout");
    d(userLogoutAsyncThunk());
  };

  let user = JSON.parse(localStorage.getItem("user"));
  console.log("🚀 ~ Header ~ user:", user);
  return (
    <div className="flex flex-col items-center justify-between w-full lg:flex-row absolute top-0 left-0 right-0 z-50 pb-0  h-22 navbar ">
      <div
        className="mt-7 mx-auto h-16 bg-white rounded-[15px] flex justify-between items-center px-2.5"
        style={{ width: "78%" }}>
        <a
          href="/"
          aria-current="page"
          aria-label="home"
          className="overflow-visible w-30 max-w-30">
          {/* Full logo for larger screens */}
          <img
            src={tokcommentHeader}
            alt="Full logo"
            className="hidden my-auto ml-5 align-middle md:inline-block w-52"
          />
          {/* Logo only for smaller screens */}
          <img
            src={tokcommentLogo}
            alt="Logo only"
            className="inline-block w-8 my-auto mr-5 align-middle md:hidden"
          />
        </a>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitEmail();
          }}
          style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              className="relative w-full text-sm text-gray-900 rounded-l-lg outline-none bg-gray-50 ring-none"
              style={{ maxWidth: "360px" }}>
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <EmailIcon />
              </div>
              <input
                className=" border-r-0 rounded-l-lg pt-3 pb-3 h-[40px] pl-10 pr-3 w-full bg-transparent border border-inline ring-none outline-none "
                placeholder="Free Weekly TikTok Trend Reports"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id="email"
                required=""
              />
            </div>
            <div className="ml-0">
              <button
                type="submit"
                className={` h-[40px] pl-4 pr-5 w-full text-sm font-medium text-center text-neutral cursor-pointer ${
                  isSuccess
                    ? "bg-pink-200 "
                    : "bg-violet-200 hover:bg-violet-300"
                } rounded-r-lg transition-all duration-350 ease-in-out ${
                  isSuccess ? "pr-6" : "sm:pr-12"
                } ${isLoading ? "opacity-50" : "opacity-100"} ${
                  isSuccess
                    ? ""
                    : "sm:bg-[url('https://assets-global.website-files.com/64d0ce443a2aa5bfebde64d2/64d9f3010b773a4d2194abfa_Arrow_Right_MD.svg')] sm:bg-no-repeat sm:bg-[right_1.7rem_center] sm:hover:bg-[90px]"
                } `}
                disabled={isLoading || isSuccess}>
                {isSuccess ? "Subscribed!" : "Subscribe"}
              </button>
            </div>
          </div>
        </form>
        {/* <iframe src="https://embeds.beehiiv.com/f327f2ce-ccf2-4221-a72e-398b77023f46?slim=true" style={{ margin: 0, borderRadius: '0px', backgroundColor: 'transparent', height: '52px', width: '100%', border: '0', overflow: 'hidden' }} frameBorder="0" scrolling="no" title="Beehiiv Embed" /> */}
      </div>
      {!user ? (
        <div className="bg-transparent mt-8 items-center flex gap-x-2 relative xl:right-[3%] right-[2%] top-[-3px]">
          <Link to="/login">
            <button
              type="button"
              className="py-2 px-3 text-white rounded bg-[#39394c] hover:bg-[#44445b] text-base h-11 font-semibold"
              style={{ fontFamily: "Proxima Nova" }}>
              Login
            </button>
          </Link>
          <Link to="/register">
            <button
              type="button"
              className="py-2 px-3 text-white rounded bg-[#e66462] hover:bg-[#ea7a78] text-base h-11 font-semibold"
              style={{ fontFamily: "Proxima Nova" }}>
              Sign Up
            </button>
          </Link>
        </div>
      ) : (
        <div className="mt-8 items-center flex gap-x-1 relative xl:right-[2%] right-[1%] top-[-3px]">
          <a href="https://tokcommentv3portal.toktools.online" target="_blank">
            <button
              type="button"
              className="py-2 px-3 text-white rounded bg-[#39394c] hover:bg-[#44445b] text-base h-11 font-semibold"
              style={{ fontFamily: "Proxima Nova" }}>
              Dashboard
            </button>
          </a>

          <button
            type="button"
            onClick={handleLogout}
            className="py-2 px-3 text-white rounded bg-[#e66462] hover:bg-[#ea7a78] text-base h-11 font-semibold"
            style={{ fontFamily: "Proxima Nova" }}>
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;
