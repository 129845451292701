const HeroSection = () => {
  return (
    <div className="z-20 w-full mx-auto mt-12 mb-6 text-center ">
      <div
        className="flex items-center justify-center mx-auto text-6xl leading-snug text-gray-800 sm:text-6xl md:text-5xl lg:text-5xl"
        style={{ width: "70%", maxWidth: "1400px", fontWeight: "700" }}>
        <div className="mt-2 ms-1 text-[60px]">TikTok Comment</div>

        <div>
          <span className="text-6xl  z-20 inline-block p-3 px-3 text-white rounded ms-3 ps-3 bg-gradient-to-r from-pink-500 to-blue-500 w-[100%] ps-3 pe-3">
            Generator
          </span>
        </div>
      </div>

      <h2
        className="m-auto mt-3 text-lg font-normal leading-relaxed text-gray-700 lg:text-2xl"
        style={{ width: "80%", maxWidth: "1200px", wordSpacing: "-1px" }}>
        Fill in the information below and download your comment image
      </h2>
    </div>
  );
};

export default HeroSection;
{
  /*
 <h1 className="text-4xl font-bold leading-tight text-gray-800 sm:text-5xl md:text-6xl lg:text-5xl">
            TikTok Comment
            <span className="relative inline-block">
                <span className="absolute inset-0 mx-2 -my-2 transform -skew-y-3 rounded h-14 bg-gradient-to-r from-pink-500 to-blue-500"></span>
                <span className="relative z-10 px-0 py-5 mx-4 my-4 text-white">
                    Generator
                </span>
            </span>
        </h1> 
    */
}
