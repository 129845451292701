import React from "react";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { userLoginAsyncThunk } from "../store/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Image from "../assets/images/tokcomment-header.png"
import Footer from "../components/Footer";
import Header from "../components/Header";

const Login = () => {
  const router = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const dispatch = useDispatch();
  const login = useSelector((state) => state?.auth);
  console.log("Login", login);

  const handleSubmit = async (values) => {
    await dispatch(
      userLoginAsyncThunk({
        email: values.email,
        password: values.password,
        router,
      })
    );
  };

  return (
    <>
    <div
        className="background-gradient absolute z-0 top-0 bottom-0 left-0 right-0 "
        style={{ pointerEvents: "none" }}
      >
        <img
          src="https://assets-global.website-files.com/64d0ce443a2aa5bfebde64d2/64df5bc08ccc30bf928bdf4b_Background%20Blur.svg"
          loading="eager"
          alt=""
          className=" w-full h-[130%] object-cover -mt-[250px] "
        />
      </div>
      <Header/>
      <div className="container">
        <div className="row">
          <div className="col-12 whole-card">
            <div className="login-container">
              <div className="login-form mt-5 main-card">
                <Link href="/">
                  <img
                    src={Image}
                    className="my-auto  LOGIN main-logo mb-5 w-52"
                    alt="TokTools logo"
                  />
                </Link>
                {/* <div className="hello">
                  <h2>Hello!</h2>
                </div>
                <div className="sign-in">
                  <p>Sign into your account here</p>
                </div> */}

                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <h5>E-mail</h5>
                        <div className="input-with-icon">
                          <i className="fa-light icon icon-tabler icon-tabler-mail fa-envelope"></i>
                          <input
                            name="email"
                            type="email"
                            className={`form-control form-control-lg ${touched.email && errors.email ? "is-invalid" : ""
                              }`}
                            placeholder="Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.email && errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <h5>Password</h5>
                        <div className="input-with-icon">
                          <i className="fa-light icon icon-tabler icon-tabler-mail fa-lock-keyhole"></i>
                          <input
                            name="password"
                            type="password"
                            className={`form-control form-control-lg ${touched.password && errors.password
                                ? "is-invalid"
                                : ""
                              }`}
                            placeholder="Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.password && errors.password && (
                            <div className="invalid-feedback">
                              {errors.password}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div className="lastlines my-3 text-end">
                        <h3>
                          
                          <span>
                            {" "}
                            <Link to="/forget" className="text-base">Forgot password?</Link>
                          </span>
                        </h3>
                      </div> */}
                      <Button
                        variant=""
                        className="pink-login"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Logging in..." : "Login"}
                      </Button>
                    </Form>
                  )}
                </Formik>



                <div className="lastlines">
                  <h3>
                    Don&apos;t you have an account?
                    <span>
                      {" "}
                      <Link to="/register" className="text-base">Sign up</Link>
                    </span>
                  </h3>
                </div>
             
              </div>
              
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  );
};

export default Login;
