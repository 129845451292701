import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedOption: "tiktok_comment",
  // selectedOption: "facebook_post_comment",
};

const slice = createSlice({
  name: "selectedOption",
  initialState,
  reducers: {
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
  },
});

export const { setSelectedOption } = slice.actions;
export default slice.reducer;
