import React, { useState, useEffect } from "react";
import verifiedIcon from "../assets/images/verified.svg";
import axios from "axios";
import {
  isMobile,
  isBrowser,
  osName,
  osVersion,
  browserName,
  browserVersion,
} from "react-device-detect";
import { basePath } from "../service/constant";
import domtoimage from "dom-to-image";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import downloadCommentImage from "../utilities/downloadCommentImage";
import { Form } from "react-router-dom";
import { toast } from "react-toastify";

const SingleTabComponent = ({
  defaultProfileImage,
  menuButtonRef,
  setIsDropdownVisible,
  isDropdownVisible,
  menuRef,
  selectedCommentIndex,
  handleImageChange,
  commentsInfo,
  setCommentsInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const [imgPathUrl, setPathUrl] = useState();
  //   const users = useSelector((state) => state?.auth.user);
  const users = "users";
  // const [username, setUsername] = useState("Username");
  const [usernameLength, setUsernameLength] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  console.log("🚀 ~ selectedFile:", selectedFile);
  const [imageFiles, setImageFiles] = useState([]);

  const [lastImages, setLastImages] = useState([]);
  const [commentData, setCommentData] = useState({
    comment: [{ text: "", imgUrl: "" }],
  });

  const [selectedCategory, setSelectedCategory] = useState("");
  const celebrityUsernames = {
    1: "@ArianaGrande",
    2: "@BarackObama",
    3: "@Beyonce",
    4: "@ElonMusk",
    5: "@JasonDerulo",
    6: "@JoeRogan",
    7: "@JustinBieber",
    8: "@KatyPerry",
    9: "@imKevinHart",
    10: "@KhloeKardashian",
    11: "@KimKardashian",
    12: "@KylieJenner",
    13: "@mrbeast",
    14: "@NickiMinaj",
    15: "@JoeBiden",
    16: "@SelenaGomez",
    17: "@TaylorSwift",
    18: "@TheRock",
    19: "@WillSmith",
    20: "@RealDonaldTrump",
    21: "@Rihanna",
  };

  function getRandomImage(category) {
    setSelectedCategory(category);
    let maxImages = 5;
    let extension = ".png";
    if (category === "celebrity") {
      maxImages = 21;
    } else if (category === "male") {
      maxImages = 41;
    } else if (category === "female") {
      maxImages = 25;
    }
    let randomNumber;

    do {
      randomNumber = Math.floor(Math.random() * maxImages) + 1;
    } while (lastImages.includes(`${category}/${randomNumber + extension}`));

    const newLastImages = [
      `${category}/${randomNumber + extension}`,
      ...lastImages.slice(0, 2),
    ];
    setLastImages(newLastImages);

    return {
      newImageSrc: require(`../assets/random-profile-images/${category}/${
        randomNumber + extension
      }`),
      newUsername: category === "celebrity" && celebrityUsernames[randomNumber],
    };
  }

  //Function to call when selected Some specific Category
  const selectMenuItem = (category, index) => {
    setSelectedCategory(category);
    // If no index is given then use the selectedCommentIndex
    // let newIndex = index || selectedCommentIndex;

    setCommentsInfo((prev) =>
      prev?.map((comment, i) => {
        let verified = comment?.isVerified;
        let userName = comment?.username;

        let { newImageSrc, newUsername } = getRandomImage(category);
        if (category === "celebrity") {
          verified = true;
          userName = newUsername;
        } else {
          verified = false;
          userName = "";
        }
        return {
          ...comment,
          imageSrc: newImageSrc,
          username: userName,
          isVerified: verified,
          comment: [{ ...comment.comment[0], text: "" }],
        };
        // }
        return comment;
      })
    );

    // Close the dropdown
    setIsDropdownVisible(false);
  };

  console.log("🚀 ~ commentsInfo:", commentsInfo);

  const selectedOption = useSelector((state) => state.selected);
  // console.log("value@@@@@ ", selectedOption);
  const [userIP, setUserIp] = useState("");
  const [osName, setOsName] = useState("");
  console.log("🚀 ~ osName:", osName);
  const [osVersion, setOsVersion] = useState("");
  console.log("🚀 ~ osVersion:", osVersion);
  const [browserName, setBrowserName] = useState("");
  const [browserVersion, setBrowserVersion] = useState("");

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setUserIp(data.ip))
      .catch((error) => console.error("Error fetching IP address:", error));

    // Extract information from the User-Agent string
    const userAgent = navigator.userAgent;
    const osNameRegex = /(Windows|Macintosh|Linux)/i;
    const osVersionRegex = /\b(?:Windows NT|Mac OS X|Linux) ([\w.-]+)/i; // Updated regex for OS version
    console.log("🚀 ~ useEffect ~ osVersionRegex:", osVersionRegex);
    const browserNameRegex = /(Chrome|Firefox|Safari|Edge|Opera)/i;
    const browserVersionRegex = /(Chrome|Firefox|Safari|Edge|Opera)\/([\d.]+)/i;

    const osNameMatch = userAgent.match(osNameRegex);
    const osVersionMatch = userAgent.match(osVersionRegex);
    console.log("🚀 ~ useEffect ~ osVersionMatch:", osVersionMatch);
    const browserNameMatch = userAgent.match(browserNameRegex);
    const browserVersionMatch = userAgent.match(browserVersionRegex);

    if (osNameMatch) setOsName(osNameMatch[1] || "Unknown OS");
    if (osVersionMatch) setOsVersion(osVersionMatch[1] || "Unknown Version"); // Updated to capture the version correctly
    if (browserNameMatch)
      setBrowserName(browserNameMatch[1] || "Unknown Browser");
    if (browserVersionMatch)
      setBrowserVersion(browserVersionMatch[2] || "Unknown Version");
  }, []);

  const setUserAgentData = (userAgent) => {
    // Regular expressions to extract OS name, version, browser name, and version
    const osRegex = /\(([^;)]+); ([^;)]+)(?:;|; wOW64;)/;
    const browserRegex = /(?:Chrome|Safari)\/([\d.]+)/;

    const osMatch = userAgent.match(osRegex);
    const browserMatch = userAgent.match(browserRegex);

    if (osMatch) {
      const [, osName, osVersion] = osMatch;
      setOsName(osName.trim());
      setOsVersion(osVersion.trim());
    }

    if (browserMatch) {
      const browserVersion = browserMatch[1];
      setBrowserVersion(browserVersion.trim());
      setBrowserName(userAgent.includes("Chrome") ? "Chrome" : "Safari");
    }
  };

  async function saveGuest() {
    try {
      // const { data } = await axios.get(
      //   // `http://ip-api.com/json/${userIP}?fields=status,message,country,regionName,city,lat,lon`
      //   `http://ip-api.com/json/`
      // );

      // console.log("ip:::", data);
      // const location = `${data.city} ${data.regionName} ${data.country}`;
      const guestData = {
        ip_address: userIP,
        os_name: osName || "",
        os_version: osVersion || "",
        browser_name: browserName || "",
        browser_version: browserVersion || "",
        // location,/
      };

      let resp = await axios.post(`${basePath}/guests`, guestData);
    } catch (err) {
      console.log("error saving guest: ", err);
    }
  }
  const onDownloadClick = (event) => {
    setLoading(true);
    const domNode = document.getElementById("fullResult");

    const scale = 5;
    domtoimage
      .toPng(domNode, {
        width: domNode.clientWidth * scale,
        height: domNode.clientHeight * scale,
        style: {
          transform: `scale(${scale})`,
          transformOrigin: "top left",
        },
      })
      .then(async function (dataUrl) {
        console.log("🚀 ~ dataUrl:", dataUrl);
        setPathUrl(dataUrl);

        const usernameInput = document.getElementById("username");
        const usernameValue = usernameInput.value;

        const commentTextarea = document.getElementById("text");
        const commentValue = commentTextarea.value;

        const commentData = {
          avatar: dataUrl ? dataUrl : "",
          // comment: [{ text: commentValue, imgUrl: imgPathUrl ? imgPathUrl : null }],
          comment: [
            { text: commentValue, imgUrl: imgPathUrl ? imgPathUrl : "" },
          ],

          commentType: "tiktok_comment",
          // commentType: selectedOption?.selectedOption || " ",
          commentUserName: usernameValue || "Username",
          userName: users?.userName || "Guest",
          // guestIp: users?.userName ? undefined : userIP,
          guestIp: userIP || "User",
          // isVerified: commentsInfo?.isVerified || false,
        };
        console.log("🚀 ~ commentData>>>>>>>>>:", commentData);

        if (!users?.userName) {
          saveGuest();
        }

        if (commentData.guestIp) {
          await axios
            .post(`${basePath}/comment/guest-comment/`, commentData, {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            })
            .then(function (response) {
              if (response && response.status == 201) {
                const link = document.createElement("a");
                // link.download = selectedOption.selectedOption + ".png";
                link.download = "comment_image.png";
                link.href = dataUrl;
                link.click();
                toast.success("Successfully save comment");
              } else {
                console.error("Failed to save comment:", response);
                toast.success("Failed to save comment:");
              }
            })
            .catch(function (error) {
              console.error("Error saving comment:", error);
              toast.error("Error saving comment:", error);
            });
        } else {
          await axios
            .post(`${basePath}/comment/`, commentData, {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            })
            .then(function (response) {
              if (response && response.status == 201) {
                // Request successful, call the download function
                const link = document.createElement("a");
                link.download = selectedOption.selectedOption + ".png";
                link.href = dataUrl;
                link.click();
                // toast.success("Successfully save comment");
              }
            })
            .catch(function (error) {
              console.log("Error saving", error);
              //   toast.error("Error saving comment data:", error);
            });
        }

        setLoading(false);
      });
  };

  //Cat: Female/male type username
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      selectedCategory === "male" ||
      selectedCategory === "female" ||
      selectedCategory !== "celebrity"
    ) {
      //Username
      if (name === "username") {
        setCommentsInfo((prev) =>
          prev.map((comment) => ({
            ...comment,
            username: value,
          }))
        );
      }
    }
    //Comment
    if (name === "text") {
      setCommentsInfo((prev) =>
        prev.map((comment, index) => {
          return {
            ...comment,
            comment: [{ ...comment.comment[0], text: value }],
          };
        })
      );
    }
  };
  return (
    <div className="flex flex-col gap-y-10">
      <div>
        <div className="flex flex-col items-start mt-2 gap-x-3">
          <div className="flex items-center ml-1 gap-x-3">
            <div className="flex items-center justify-center w-12 h-12 overflow-hidden border border-gray-200 rounded-full min-w-12 min-h-12">
              <img
                src={
                  commentsInfo[selectedCommentIndex]?.imageSrc
                    ? commentsInfo[selectedCommentIndex]?.imageSrc
                    : commentsInfo[0]?.imageSrc || defaultProfileImage
                }
                alt="Profile"
                className="object-cover w-full h-full profile-image"
              />
            </div>
            <div className="flex items-center p-4 py-2 border-gray-100 rounded-lg gap-x-3">
              <button
                type="button"
                className="rounded-md bg-gray-700 px-3 py-1.5 text-sm text-white shadow-sm hover:bg-gray-800"
                onClick={() => document.getElementById("photo").click()}>
                Upload
              </button>
              <span className="text-sm text-gray-500">OR</span>
              {/* New Dropdown Button */}
              <div className="relative inline-block text-left group">
                <button
                  ref={menuButtonRef}
                  type="button"
                  className="rounded-md bg-gray-100 px-3 py-1.5 text-sm text-gray-600 hover:bg-gray-200 inline-flex items-center"
                  onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
                  Generate Random
                </button>
                <div
                  className={`absolute z-10 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-transform duration-300 ease-in-out ${
                    isDropdownVisible
                      ? "scale-y-100 opacity-100"
                      : "scale-y-0 opacity-0"
                  }`}
                  ref={menuRef}
                  style={{ width: "100px" }}>
                  <div className="py-1" role="none">
                    <button
                      onClick={() => selectMenuItem("male")}
                      className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                      role="menuitem">
                      Male
                    </button>
                    <button
                      onClick={() => selectMenuItem("female")}
                      className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                      role="menuitem">
                      Female
                    </button>
                    <button
                      onClick={() => selectMenuItem("celebrity")}
                      className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                      role="menuitem">
                      Celebrity
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="flex items-center">
          <span
            className={`ml-2 cursor-pointer rounded-md px-1 py-0.5 text-xs hover:bg-opacity-75 ${
              commentsInfo[0]?.isVerified
                ? "bg-gray-200 text-gray-700"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => {
              setCommentsInfo((prev) => {
                const updatedComments = prev.map((comment) => ({
                  ...comment,
                  isVerified: !comment.isVerified,
                }));
                console.log("Updated Comments: ", updatedComments);
                return updatedComments;
              });
            }}>
            <div className="flex items-center">
              <span>Verified</span>
              <span
                className={`bg-no-repeat bg-contain w-[1em] h-[1em] inline-block ml-2 ${
                  !commentsInfo[0]?.isVerified ? "grayscale" : ""
                }`}
                style={{
                  backgroundImage: `url(${verifiedIcon})`,
                }}></span>
            </div>
          </span>
        </div>
        <div className="mt-2 ml-1">
          <input
            type="text"
            id="username"
            name="username"
            className="block  rounded-md border-0 py-1.5 px-2 w-[80%] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm"
            placeholder="username"
            value={commentsInfo[0]?.username}
            onChange={(e) => handleInputChange(e)}
            maxLength="24"
          />
          <label className="text-xs text-gray-400 ml-0.5">
            {commentsInfo?.nameCharacterCount || 0}
            /24 characters
          </label>
        </div>
      </div>

      <div>
        {/* <label htmlFor="image-upload" className="image-upload">
          <input
            type="file"
            id="photo"
            name="commentImgUrl" 
            onChange={(e) => handleImageChange(selectedCommentIndex, e)}
            accept="image/*"
          />
        </label>

        {commentsInfo[selectedCommentIndex]?.imgUrl && (
          <div>
            <p>Selected File: {commentsInfo[selectedCommentIndex]?.imgUrl}</p>
            <img
              src={commentsInfo[selectedCommentIndex]?.imgUrl}
              alt="Selected File"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
        )} */}

        <div className="ml-1 ">
          <textarea
            id="text"
            rows="3"
            className="block rounded-md border-0 py-1.5 px-2 w-[80%] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm"
            placeholder="Write your comment..."
            value={commentsInfo[0]?.comment[0]?.text}
            onChange={(e) => handleInputChange(e)}
            maxLength="150"
            name="text"></textarea>
          <label className="text-xs text-gray-400 mt-2 ml-0.5">
            {commentsInfo?.commentCharacterCount || 0}
            /150 characters
          </label>
        </div>
      </div>

      <div style={{ padding: "10px", overflow: "hidden" }}>
        {" "}
        {/* Adjust padding as needed */}
        <button
          id="download"
          type="submit"
          disabled={loading}
          style={{
            background:
              "linear-gradient(135deg, rgb(0 147 255) 0%, rgb(238 29 210) 100%)",
          }}
          className={`download-btn mt-12  py-3 text-sm rounded-full font-medium transition-all w-full duration-300 ease-in-out ${
            loading
              ? "opacity-50"
              : "bg-gradient-to-r from-green-500 to-blue-500 text-white hover:scale-105"
          }`}
          // className="w-full py-3 mt-6 text-sm font-medium text-white transition-all duration-300 ease-in-out rounded-full bg-gradient-to-r from-indigo-500 to-pink-500 hover:from-indigo-600 hover:to-pink-600 hover:scale-105"
          // onClick={downloadCommentImage}
          onClick={onDownloadClick}>
          {loading ? "Loading..." : "Download Comment"}
        </button>
      </div>

      {/* Hidden File Input */}

      <input
        className="hidden"
        type="file"
        id="photo"
        name="imgUrl" // Update the name attribute
        onChange={(e) => handleImageChange(selectedCommentIndex, e)}
        accept="image/*"
      />
    </div>
  );
};

export default SingleTabComponent;
