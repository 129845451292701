import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userRegisterAsyncThunk } from "../store/authSlice";
// import Header from "../src/components/Header";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import Image from "../assets/images/tokcomment-header.png"
import Header from "../components/Header";

const Register = () => {
  const router = useNavigate();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    userName: Yup.string().required("Username is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const register = useSelector((state) => state?.auth);
  console.log("Register", register);

  const dispatch = useDispatch();
  const [btn, setBtn] = useState(true);

  const handleSubmit = async (values, { setSubmitting }) => {
    const response = await dispatch(
      userRegisterAsyncThunk({
        userName: values.userName,
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        router,
      })
    );

    if (response && response.status === 201) {
      // Handle successful registration (optional)
      console.log("Registration successful");
    }

    setSubmitting(false);
  };
  return (
    <>
      {/* <Header updateState={updateState} /> */}
      <div
        className="background-gradient absolute z-0 top-0 bottom-0 left-0 right-0 "
        style={{ pointerEvents: "none" }}
      >
        <img
          src="https://assets-global.website-files.com/64d0ce443a2aa5bfebde64d2/64df5bc08ccc30bf928bdf4b_Background%20Blur.svg"
          loading="eager"
          alt=""
          className=" w-full h-[130%] object-cover -mt-[250px] "
        />
      </div>
      <Header/>
      <div className="login-container">
        <div className="login-form mt-5 main-card">
          <Link to="/login">
            <img
              src={Image}
              className="my-auto main-logo mb-5  w-52"
              alt="TokTools logo"
            />
          </Link>{" "}
          {/* <div className="hello">
            <h2>Join us</h2>
          </div>
          <div className="sign-in">
            <p>Create your account here</p>
          </div> */}
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              userName: "",
              email: "",
              password: "",
              rememberMe: false,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                
                  <div className="form-group">
                    <h5>First Name</h5>
                    <div className="input-with-icon fes">
                      <i className="fa-light icon icon-tabler icon-tabler-mail "></i>
                      <Field
                        name="firstName"
                        type="text"
                        value={values.firstName}
                        className={`form-control reg form-control-lg ${
                          touched.firstName && errors.firstName
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="john"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <h5>Last Name</h5>
                    <div className="input-with-icon fes right">
                      <i className="fa-light icon icon-tabler icon-tabler-mail "></i>
                      <Field
                        value={values.lastNname}
                        name="lastName"
                        type="text"
                        className={`form-control reg form-control-lg  ${
                          touched.lastName && errors.lastName
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Doe"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                

                <div className="form-group">
                  <div className="input-with-icon es">
                    <i className="fa-light icon icon-tabler icon-tabler-mail"></i>
                    <h5>User Name</h5>
                    <Field
                      value={values.userName}
                      name="userName"
                      type="text"
                      className={`form-control reg form-control-lg ${
                        touched.userName && errors.userName ? "is-invalid" : ""
                      }`}
                      placeholder="Your Username"
                    />
                    <ErrorMessage
                      name="userName"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-with-icon es">
                    <i className="fa-light icon icon-tabler icon-tabler-mail"></i>
                    <h5>Email Address</h5>
                    <Field
                      value={values.email}
                      name="email"
                      type="email"
                      className={`form-control reg form-control-lg ${
                        touched.email && errors.email ? "is-invalid" : ""
                      }`}
                      placeholder="Enter your Email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-with-icon es">
                    <i className="fa-light icon icon-tabler icon-tabler-mail"></i>
                    <h5>Password</h5>
                    <Field
                      value={values.password}
                      name="password"
                      type="password"
                      className={`form-control reg form-control-lg ${
                        touched.password && errors.password ? "is-invalid" : ""
                      }`}
                      placeholder="Minimum 6 characters"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-with-icon es">
                    <i className="fa-light icon icon-tabler icon-tabler-mail"></i>
                    <h5>Confirm Password</h5>
                    <Field
                      // value={values.password}
                      name="confirm password"
                      type="password"
                      onChange={(e) => {
                        // console.log("GHDSJF");
                        if (values.password == e.target.value) {
                          setBtn(true);
                        } else {
                          setBtn(false);
                        }
                      }}
                      className={`form-control reg form-control-lg ${
                        !btn ? "is-invalid" : ""
                      }`}
                      placeholder="Minimum 6 characters"
                    />
                    <p className="invalid-feedback">Password didn't match </p>
                  </div>
                </div>

                <Button
                  type="submit"
                  variant=""
                  className="register-btn"
                  disabled={isSubmitting || !btn}
                >
                  {isSubmitting ? "Registering..." : "Register"}
                </Button>
              </Form>
            )}
          </Formik>
          <div className="lastlines mt-4">
                  <h3>
                    Already Registered?
                    <span>
                      <Link to="/login">Log in</Link>
                    </span>
                  </h3>
                </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
