

//add footer componenet
const AboutSection = () => {
    return (
        <div className='bg-white  z-20  '>
            <div className="mx-auto px-4 sm:px-6 lg:px-8 w-full sm:w-3/4 md:w-2/3 lg:max-w-4xl border-y py-16 border-gray-200" style={{ width: '90%', maxWidth: '1400px' }}>
                <h1 className="mb-12 text-center  text-xl font-extrabold text-gray-900 leading-tight">About TikTok Comment Sticker Reply Generator</h1>

                <p className="mb-8 text-sm-xl  text-gray-800 leading-relaxed">
                    Create custom comments for your TikTok videos in Seconds. When finished, download your Comment as an image, and add it to your video via any Desktop or Mobile video editor. Perfect for TikTok Ads Specialists or UGC Creators who are struggling to find the perfect comment that grabs the viewers attention.
                </p>

                <div className="space-y-6">
                    <p className="text-sm-xl  text-gray-800 leading-relaxed">
                        <strong className="font-bold">TikTok Ads:</strong> If you're struggling to create new TikTok creatives for your ads, just take your best performing video(s) and add a Video Comment Reply sticker on top of the video and give it a whole new twist.
                    </p>

                    <p className="text-sm-xl  text-gray-800 leading-relaxed">
                        <strong className="font-bold">UGC Creators:</strong> Adding Comment Replies to your videos is a great way to frame the attention of the audience and grab their instantly. But finding the right comment to use can be hard. Until now!
                    </p>
                </div>
            </div>
        </div>
    )
}



export default AboutSection