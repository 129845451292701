import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
// import Link from "next/link";
import { toast } from "react-toastify";
// import Header from "../src/components/Header";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { userResetAsyncThunk } from "../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Image from "../assets/images/tokcomment-header.png"

const Reset = () => {
  const router = useNavigate();
  const { token } = router.query;
  const [confirmPassword, setConfirmPassword] = useState("");

  const [password, setPassword] = useState("");
  const reset = useSelector((state) => state?.auth);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(userResetAsyncThunk({ password, token, router }));
  };

  return (
    <>
      {/* <Header updateState={updateState} /> */}

      <div className="login-container">
        <div className="login-form mt-5 main-card">
          <Link to="/login">
            <img
              src={Image}
                            className="my-auto main-logo ml-5 w-52"
              alt="TokTools logo"
            />
          </Link>
          <div className="hello">
            <h2>Hello!</h2>
          </div>
          <div className="sign-in">
            <p>Reset your password here</p>
          </div>

          {/* Form component starts here */}
          <Form method="post" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="input-with-icon">
                <i className="fa-light icon icon-tabler icon-tabler-mail fa-lock-keyhole"></i>
                <input
                  name="password"
                  type="password"
                  className={`form-control form-control-lg`}
                  placeholder="Enter Passwprd"
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="input-with-icon">
                <i className="fa-light icon icon-tabler icon-tabler-mail fa-lock-keyhole"></i>
                <input
                  name="confirmpassword"
                  type="password"
                  className={`form-control form-control-lg`}
                  placeholder="confirm Password"
                  // value={values.password}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  value={confirmPassword} // Use the confirmPassword state variable to store the value
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            {/* </div> */}
            <Button variant="" className="pink-login" type="submit">
              Reset
            </Button>
          </Form>
          {/* Form component ends here */}

          {/* <div className="lastlines"> */}
          {/* <h3>
              Forgot password?
              <span>
                <Link href="/forget">Reset</Link>
              </span>
            </h3>
          </div>
          <div className="lastlines">
            <h3>
              Don&apos;t you have an account?
              <span>
                <Link href="/register">Sign Up</Link>
              </span>
            </h3> */}
          {/* </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Reset;
