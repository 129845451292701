import React, { useEffect, useRef, useState } from "react";
import downloadCommentImage from "../utilities/downloadCommentImage";
import defaultProfileImage from "../assets/images/default-profile-image.png"; // Adjust the import path as necessary
import canvas from "../assets/images/canvas.png";
import CommentBubble from "../components/CommentBubble";
import Footer from "../components/Footer";
import AboutSection from "../components/AboutSection";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import useClickOutside from "../components/useClickOutside";
import BulkTableRow from "../components/BulkTableRow";
import { AiOutlineFileExcel } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import LogoWall from "../components/LogoWall";

import SingleTabComponent from "../components/SingleTabComponent.jsx";
import BulkTabComponent from "../components/BulkTabComponent.jsx";
function Home() {
  const [uploadResponse, setUploadResponse] = useState(null);
  const initialCommentInfo = {
    imageSrc: "",
    username: "",
    comment: [
      {
        text: "",
        imgUrl: "",
      },
    ],
    isVerified: false,
    nameCharacterCount: 0,
    commentCharacterCount: 0,
  };
  const [commentsInfo, setCommentsInfo] = useState([initialCommentInfo]);
  console.log("commentsInfo>>>>>>>>>>>>>>>>>", commentsInfo);
  const [selectedCommentIndex, setSelectedCommentIndex] = useState(0);
  // console.log("🚀 ~ Home ~ selectedCommentIndex:", selectedCommentIndex);

  useEffect(() => {
    setSelectedCommentIndex(uploadResponse?.excelData);
  }, [uploadResponse?.excelData]);

  // const addComment = () => {
  //   setCommentsInfo([...commentsInfo, initialCommentInfo]);
  // };

  // const removeComment = (index) => {
  //   const updatedCommentsInfo = commentsInfo.filter((_, i) => i !== index);
  //   console.log({ updatedCommentsInfo });
  //   setSelectedCommentIndex(index - 1 >= 0 ? index - 1 : 0);
  //   setCommentsInfo(updatedCommentsInfo);
  //   //make setSelectedCommentIndex be the previous idnex of this one if it exists otherwise make it 0
  // };
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setCommentsInfo((prevCommentData) => ({
  //     ...prevCommentData,
  //     [name]: value,
  //   }));
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update the character count based on the input field
    if (name === "username") {
      const newCharacterCount = value.length;
      console.log(
        "🚀 ~ handleInputChange ~ newCharacterCountuuuuuuu:",
        newCharacterCount
      );
      setCommentsInfo((prevCommentData) => ({
        ...prevCommentData,
        [name]: value,
        nameCharacterCount: newCharacterCount,
      }));
    } else if (name === "text") {
      const newCharacterCount = value.length;
      console.log(
        "🚀 ~ handleInputChange ~ newCharacterCountttttttttttt:",
        newCharacterCount
      );
      setCommentsInfo((prevCommentData) => {
        return {
          ...prevCommentData,
          comment: [{ text: value }],
          commentCharacterCount: newCharacterCount,
        };
      });
    } else {
      setCommentsInfo((prevCommentData) => ({
        ...prevCommentData,
        [name]: value,
      }));
    }
  };

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];

    console.log("FUNCION FILE IMAGE CHANGE: ", file);
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const updatedCommentsInfo = [...commentsInfo];
      const fieldToUpdate = event.target.name;
      console.log("🚀 ~ handleImageChange ~ fieldToUpdate:", fieldToUpdate);

      if (fieldToUpdate === "imgUrl") {
        // Update the 'imageSrc' field
        updatedCommentsInfo[0] = {
          ...updatedCommentsInfo[0],
          imageSrc: e.target.result,
        };
      }

      setCommentsInfo(updatedCommentsInfo);
    };
    reader.readAsDataURL(file);
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);

  useClickOutside([menuRef], () => setIsDropdownVisible(false));

  const [selectedTab, setSelectedTab] = useState("single");

  return (
    <div>
      <div
        className="absolute top-0 bottom-0 left-0 right-0 z-0 background-gradient "
        style={{ pointerEvents: "none" }}>
        <img
          src="https://assets-global.website-files.com/64d0ce443a2aa5bfebde64d2/64df5bc08ccc30bf928bdf4b_Background%20Blur.svg"
          loading="eager"
          alt=""
          className="w-full h-[130%] object-cover -mt-[210px]"
          style={{
            minWidth: "100%",
            width: "100%",
          }}
        />
      </div>

      {/* Header/Nav */}
      <div className="bg-[#f7f8f9] ">
        <Header />
      </div>

      {/* Genrate comment Headings */}
      <div className="bg-[#f7f8f9] flex flex-col text-black HomepageContainer ">
        <div className="py-16 "></div>
        <HeroSection />

        <div
          className="flex flex-col items-center content-center gap-0 mx-auto mb-8 lg:items-start lg:flex-row"
          style={{ width: "90%", maxWidth: "900px" }}>
          <div className="z-40 w-full mt-4 mb-4 lg:w-fit ">
            <div
              className={`md:sticky md:top-0 transition-all mx-auto   bg-white z-40  p-3 rounded-3xl  lg:rounded-r-none lg:w-[500px] w-full max-w-[490px] h-fit   lg:min-h-[735px] min-h-[535px] `}>
              <div className="mb-2  p-[12px] rounded-md  z-40  ">
                <div className="relative flex flex-row items-center">
                  <button
                    className={`w-1/2 py-2 text-sm font-medium rounded-md outline-none border-none ring-0 transition-all duration-300 ease-in-out ${
                      selectedTab === "single"
                        ? "gradient-text-indigo-pink"
                        : "text-gray-700"
                    } mr-2`}
                    onClick={() => setSelectedTab("single")}>
                    Single
                  </button>
                  <button
                    className={`w-1/2 py-2 text-sm font-medium outline-none border-none ring-0 rounded-md transition-all duration-300 ease-in-out ${
                      selectedTab === "bulk"
                        ? "gradient-text-green-blue"
                        : "text-gray-700"
                    }`}
                    onClick={() => setSelectedTab("bulk")}>
                    Bulk
                  </button>
                  <div className="absolute left-0 bottom-0 w-full h-0.5 bg-gray-100 rounded-t-full"></div>
                  <div
                    className={`absolute left-0 bottom-0 transition-all duration-200 ease-in-out w-1/2 h-0.5 rounded-t-full ${
                      selectedTab === "single"
                        ? "ml-0 bg-gradient-to-r from-indigo-500 to-pink-600"
                        : "ml-[50%] bg-gradient-to-r from-green-500 to-blue-500"
                    }`}></div>
                </div>
              </div>

              <div className="px-6 mt-4 overflow-x-none ">
                {selectedTab === "single" && (
                  <SingleTabComponent
                    commentsInfo={commentsInfo}
                    defaultProfileImage={defaultProfileImage}
                    menuButtonRef={menuButtonRef}
                    setIsDropdownVisible={setIsDropdownVisible}
                    isDropdownVisible={isDropdownVisible}
                    menuRef={menuRef}
                    selectedCommentIndex={selectedCommentIndex}
                    handleInputChange={handleInputChange}
                    setCommentsInfo={setCommentsInfo}
                    handleImageChange={handleImageChange}
                  />
                )}

                {selectedTab === "bulk" && (
                  <BulkTabComponent
                    commentsInfo={commentsInfo}
                    setCommentsInfo={setCommentsInfo}
                    selectedCommentIndex={selectedCommentIndex}
                    setIsDropdownVisible={setIsDropdownVisible}
                    setSelectedCommentIndex={setSelectedCommentIndex}
                    BulkTableRow={BulkTableRow}
                    handleInputChange={handleInputChange}
                    // removeComment={removeComment}
                    useClickOutside={useClickOutside}
                    handleImageChange={handleImageChange}
                    // addComment={addComment}
                    AiOutlinePlus={AiOutlinePlus}
                    AiOutlineFileExcel={AiOutlineFileExcel}
                    selectedTab={selectedTab}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="z-20 px-4 pt-4 pb-2 mt-4 bg-white h-fit w-96 rounded-3xl lg:rounded-r-3xl lg:rounded-l-none ">
            <div
              className=" bg-cover rounded-md h-[44rem] pb-10 pt-24 px-10 mx-auto w-full"
              style={{
                backgroundImage: `url(${canvas})`,
              }}>
              <div id="fullResult" className="pb-5 w-fit">
                <CommentBubble
                  username={commentsInfo[0]?.username}
                  isVerified={commentsInfo[0]?.isVerified}
                  imageSrc={commentsInfo[selectedCommentIndex]?.imageSrc}
                  imageSrcRandom={commentsInfo[0]?.imageSrc}
                  text={
                    commentsInfo[0]?.comment[0]?.text ||
                    "Write any comment and see what happens 😁"
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <LogoWall />

        <AboutSection />

        <Footer />
      </div>
    </div>
  );
}

export default Home;
