//import stuff
import React, { useState, useEffect, useRef } from "react";
import defaultProfileImage from "../assets/images/default-profile-image.png";
import { FiArrowUp } from "react-icons/fi";
import { MdShuffle } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";

const BulkTableRow = ({
  comment,
  index,
  handleBulkInputChange,
  removeComment,
  selectMenuItem,
  // handleImageChange,
}) => {
  // console.log("🚀 ~ index:", index)
  console.log("🚀 ~ comment:", comment);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close dropdown if click is outside the menu and button
      if (
        isDropdownVisible &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownVisible]);

  return (
    <>
      {/* Username */}
      <td className="px-2 py-2 whitespace-nowrap">
        <input
          type="text"
          id="userName"
          name="userName" // Make sure the name attribute matches the field name
          placeholder="userName"
          value={comment?.userName || ""}
          onChange={(e) => handleBulkInputChange(index, e)}
          className="w-full px-1 py-1 text-xs text-gray-900 border-0 rounded-md shadow-sm"
        />
      </td>

      {/* Comment */}
      <td className="px-2 py-2 whitespace-nowrap">
        <input
          type="text"
          id="comment"
          name="comment"
          placeholder="Comment"
          value={comment.comment || ""}
          onChange={(e) => handleBulkInputChange(index, e)}
          className="w-full px-1 py-1 text-xs text-gray-900 border-0 rounded-md shadow-sm"
        />
      </td>

      {/* Verified Checkbox */}
      <td className="px-2 py-2 text-center whitespace-nowrap">
        <span
          className={`ml-2 cursor-pointer rounded-md px-1 py-0.5 text-xs hover:bg-opacity-75 ${
            comment.verified
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => {
            handleBulkInputChange(index, {
              target: { name: "verified", value: !comment.verified },
            });
          }}>
          Verified
        </span>
      </td>

      {/* Profile Picture and Dropdown */}
      <td className="px-2 py-2 text-center whitespace-nowrap">
        <div className="flex items-center justify-center space-x-2">
          {" "}
          {/* Adjust space-x-2 for spacing */}
          {/* Profile Image */}
          <img
            src={comment.avatar || defaultProfileImage}
            alt="Profile"
            className="object-cover w-5 h-5 rounded-full"
          />
          {/* Buttons and Dropdown */}
          <div className="relative inline-block text-left">
            <div className="flex items-center px-1 py-2 space-x-1 border border-gray-100 rounded-lg">
              <button
                type="button"
                className="rounded-md bg-gray-700 px-1 py-0.5 text-xs text-white shadow-sm hover:bg-gray-800"
                onClick={() =>
                  document.getElementById(`photo-${index}`).click()
                }>
                <FiArrowUp className="text-xxs" />{" "}
              </button>

              <span
                className="text-xs text-gray-500"
                style={{ fontSize: "10px" }}>
                OR
              </span>

              <button
                ref={buttonRef}
                type="button"
                className="rounded-md bg-gray-100 px-2 py-0.5 text-xs text-gray-600 hover:bg-gray-200"
                onClick={toggleDropdown}>
                <MdShuffle className="text-xxs" />{" "}
              </button>
            </div>

            {/* Dropdown Menu */}

            <div
              className={`absolute z-10 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-transform duration-300 ease-in-out ${
                isDropdownVisible
                  ? "scale-y-100 opacity-100"
                  : "scale-y-0 opacity-0"
              }`}
              ref={menuRef}
              style={{ width: "100px" }}>
              {/* <div className="py-1">
                <button
                  onClick={() => {
                    selectMenuItem("male", index);
                    toggleDropdown();
                  }}
                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                >
                  Male
                </button>
                <button
                  onClick={() => {
                    selectMenuItem("female", index);
                    toggleDropdown();
                  }}
                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                >
                  Female
                </button>
                <button
                  onClick={() => {
                    selectMenuItem("celebrity", index);
                    toggleDropdown();
                  }}
                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                >
                  Celebrity
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </td>

      {/* Delete Button */}
      {!index && (
        <td className="px-2 py-2 text-center whitespace-nowrap">
          <button
            type="button"
            className="inline-flex items-center justify-center px-2 py-1 text-sm font-medium transition duration-150 ease-in-out rounded-full bg-neutral-100 text-neutral-600 hover:bg-neutral-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-500"
            onClick={() => removeComment(index)}
            aria-label="Delete">
            <AiFillDelete className="text-xs" />
          </button>
        </td>
      )}

      <input
        className="hidden"
        type="file"
        name="avatar"
        id={`photo-${index}`}
        onChange={(e) => handleBulkInputChange(index, e)}
        accept="image/*"
      />
    </>
  );
};

export default BulkTableRow;
