import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../service/ApiRequests"; 
import { catchAsync, handleLoadingErrorParamsForAsycThunk, reduxToolKitCaseBuilder } from "./detectError";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// user Login With Credentials
export const userLoginAsyncThunk = createAsyncThunk(
  "auth/userLoginAsyncThunk",
  catchAsync(async ({  email, password,router }, _) => {
    const response = await ApiRequests.login({ email, password });
    console.log('response::: ', response)
    if (response) {
      if (response?.status == 200) {
        toast.success("LoggedIn Successfully!", {
          autoClose: 2000,
        });
        // if (router) router.push("/")
        window.location.href = '/';

      } else {
        // if (router) router.push("/login")
        window.location.href = '/login';

        toast.error(response.error)
      }
    }
    return response?.data;
  })
);
// user Login With Credentials
export const authenticateAsyncThunk = createAsyncThunk(
  "auth/authenticateAsyncThunk",
  catchAsync(async (__, _) => {
    const response = await ApiRequests.authenticate();
    if (response) {
      if (response?.status == 200) {
        // console.log("move")
      } else {
        toast.error(response.error)
      }
    }
    return response?.data;
  })
);
// user Login With Credentials
export const refreshTokensAsyncThunk = createAsyncThunk(
  "auth/refreshTokensAsyncThunk",
  catchAsync(async ({ router, callBack }, _) => {
    // const response = await ApiRequests.refreshTokens({ refreshToken: JSON.parse(refreshToken) });
    const refreshToken = JSON.parse(localStorage.getItem("app-refresh-token"))
    const response = await ApiRequests.refreshTokens({ refreshToken });
    console.log("response>>>", response);
    if (callBack) callBack();
    if (response) {
      if (response?.status == 200) {
        // history("/")
      } else {
        toast.error(response.error)
      }
    }
    return response.data;
  })
);
export const userLogoutAsyncThunk = createAsyncThunk(
  "auth/userLogoutAsyncThunk",
      catchAsync(async () => {
    const refreshTokenObj = JSON.parse(localStorage.getItem('app-refresh-token'));
    console.log("🚀 ~ catchAsync ~ refreshTokenObj:", refreshTokenObj)
    const refreshToken = refreshTokenObj.token;

    const response = await ApiRequests.logout({ refreshToken });
    console.log("respose:::", response)
    if (response) {
      if (response?.status == 200) {
        localStorage.removeItem('app-access-token');
        localStorage.removeItem('app-refresh-token');
        localStorage.removeItem('user');
        toast.success("LogOut Successfully!!!", {
          autoClose: 2000,
        });
        // router.push("/login");
        window.location.href = '#/login';

      } else {
        toast.error(response.error)
      }
    }
    return true;
  })
);


// user register With Credentials
export const userRegisterAsyncThunk = createAsyncThunk(
  "auth/userRegisterAsyncThunk",
  catchAsync(async ({ userName,  email, password, firstName, lastName, router }) => {
    const response = await ApiRequests.register({  userName,   email, password, firstName, lastName });
    if (response) {
      if (response?.status == 201) {
        toast.success("Registered Successfully!", {
          autoClose: 2000,
        });
        window.location.href = '/login';
      
    } else {
      toast.error(response.error)
    }
    }
    return response?.data;
  })
);
// forget
export const userForgetAsyncThunk = createAsyncThunk(
  'auth/userForgetAsyncThunk',
  async ({ email, router}) => {
    try {
      const response = await ApiRequests.forget({ email });
      if (response && response?.status === 201) {
        toast.success('Password reset link sent successfully!', {
          autoClose: 2000,
        });
      } else {
        toast.error('Failed to send reset link. Please try again later.');
      }
      return response?.data;
    } catch (error) {
      toast.error('An error occurred. Please try again later.');
      throw error;
    }
  }
);

export const userResetAsyncThunk = createAsyncThunk(
  'auth/userResetAsyncThunk',
  async ({ password,token, router }) => {
    console.log('password:: ', password)
    console.log('token:: ', token)
    try {
      const response = await ApiRequests.reset({password, token });
      if (response && response?.status === 204) {
        toast.success('Password changed successfully!', {
          autoClose: 2000,
        });
        router.push('/login')
      } else {
        toast.error('Failed . Please try again later.');
      }
      return response?.data;
    } catch (error) {
      toast.error('An error occurred. Please try again later.');
      throw error;
    }
  }
);







const user = () => {
  try {
    let user = localStorage.getItem('user');
    if (user) user = JSON.parse(user)
    return user ?? {}
  } catch (error) {
    return {}
  }
}
const initialState = {
  
  user: user(),
  tokens: null,
  
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},

};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    storeUser: (state, action) => {
      state.user = action.payload
    },
    setUser(state, action) {
      state.user = action.payload
    },
    setTokens(state, action) {
      state.tokens = action.payload
    },
  
    
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLoginAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload?.user;
        state.tokens = action.payload?.tokens;
        console.log("ddddddd:", action.payload);
        localStorage.setItem('app-access-token', JSON.stringify(action.payload?.tokens?.access));
        localStorage.setItem('app-refresh-token', JSON.stringify(action.payload?.tokens?.refresh));
        localStorage.setItem('user', JSON.stringify(action.payload?.user));
      })
      .addCase(userLogoutAsyncThunk.fulfilled, (state, action) => {
        state.user = null
      })
      .addCase(userRegisterAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(authenticateAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload?.user;
      })
      .addCase(refreshTokensAsyncThunk.fulfilled, (state, action) => {
        // state.user = action.payload;
        console.log("refreshTokensAsyncThunk:", action.payload)
        localStorage.setItem('app-access-token', JSON.stringify(action.payload?.access?.token));
        localStorage.setItem('app-refresh-token', JSON.stringify(action.payload?.refresh?.token));
      })



      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...(reduxToolKitCaseBuilder([
            userLoginAsyncThunk,
            userRegisterAsyncThunk,
            refreshTokensAsyncThunk,
            authenticateAsyncThunk,
            userLogoutAsyncThunk,
            userForgetAsyncThunk,
            userResetAsyncThunk,
           
          ]))
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default authSlice.reducer;
export const { setLoading, storeUser, setUser, setTokens,} = authSlice.actions;
